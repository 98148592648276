import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space,  Drawer, notification, Avatar, Select, Image, Upload, message,  Progress } from 'antd';
import { DeleteOutlined, LoadingOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';

function Imagens() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Mapas, setMapas] = useState([]);
  const [MapaId, setMapaId] = useState('');
  const [Imagens, setImagens] = useState([]);

  const MapasFilter = MapaId === '' ? Imagens : Imagens.filter(e => e.Mapa === MapaId);

  const [Id, setId] = useState('');
  const [Imagem, setImagem] = useState('');
  const [Mapa, setMapa] = useState('');


  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setImagem(Model.Imagem)
    setMapa(Model.Mapa)
  };

  function Limpar() {
    setNovo(false)
    setId('');
    setImagem('');
    setMapa('');
  };

  async function BuscarMapas() {
    axios({
      method: 'post',
      url: Config.Url + 'ListarMapasAll',
      data: { Buscar: `%%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setMapas(e.result);
        } else {
          setMapas([]);
        }
      })
  };

  async function BuscarImagens() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarImagens'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setImagens(e.result);
        } else {
          setLoadingPage(false);
          setImagens([]);
        }
      })
  };

  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'CriarImagem',
      data: {
        Imagem:Imagem,
        Mapa:Mapa
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarImagens()
          Limpar()
          message.success('Imagem criada com sucesso')
        } else {
          BuscarImagens()
          message.success('Falha ao criar imagem')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarImagem',
      data: {
        Id: Id,
        Imagem:Imagem,
        Mapa:Mapa
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarImagens()
          Limpar()
          message.success('Imagem editada com sucesso')
        } else {
          BuscarImagens()
          message.success('Falha ao editar imagem')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarImagem',
      data: {
        Id: Model.Id
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarImagens()
          Limpar()
          message.success('Imagem excluída com sucesso')
        } else {
          BuscarImagens()
          message.success('Falha ao excluir imagem')
        }
      })
  };

  useEffect(() => {
    BuscarMapas()
    BuscarImagens()
  }, [])


  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }

  function FiltrarMerediano(Id) {
    const Res = Mapas.filter(e => (e.Id === Id))
    return Res.map(e => e.Tecnica).toString()
  };


  const columns = [
    {
      title: 'MAPA',
      dataIndex: 'Mapa',
      key: 'Mapa',
      render: (dataIndex, key) =>
        <text>{FiltrarMerediano(dataIndex)}</text>
    },
    {
      title: 'IMAGEM',
      dataIndex: 'Imagem',
      key: 'Imagem',
      render: (dataIndex,) =>
        <di>
          <Avatar size={60} src={<Image style={{ width: 60, height: 60, borderRadius: 60 }} src={dataIndex === '' ? require('./../../Images/semimagem.png') : Config.Url + 'Mapas/' + dataIndex} />} />
        </di>
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  const uploadButton = (
    <div style={{ width: 450, height: 80, marginBottom: 10, backgroundColor: '#eee', justifyContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
      {Loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Imagem
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      setImagem(info.file.name);
    }
  };
  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          <Select onSelect={(e) => setMapaId(e)} size='large' style={{ width: 280 }} placeholder="Técnica">
            <option value="" >Todos</option>
            {Mapas.map((e, index) => (
              <option key={index} value={e.Id} >{e.Tecnica}</option>
            ))}
          </Select>
        </div>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={MapasFilter} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar imagem" : "Nova imagem"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>


        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Mapa</label>
            <Select value={Mapa} onSelect={(e) => setMapa(e)} size='large' style={{ width: '100%' }} placeholder="Mapa">
              <option value="" >Selecione um mapa</option>
              {Mapas.map((e, index) => (
                <option key={index} value={e.Id} >{e.Tecnica}</option>
              ))}
            </Select>
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Upload
            name="file"
            listType="picture"
            className="avatar-uploader"
            action={Config.Url + 'UploadsMapas'}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {Imagem ? (
              <Avatar
                src={Config.Url + 'Mapas/' + Imagem}
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: 10,
                  marginBottom: 15
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>


      </Drawer>

    </div>
  );
};



export default Imagens;
