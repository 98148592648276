import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function Fitoterapia() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Fitoterapia, setFitoterapia] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Nome, setNome] = useState('');
  const [IndicacaoMTC, setIndicacaoMTC] = useState('');
  const [IndicacaoClinica, setIndicacaoClinica] = useState('');

  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setNome(Model.Nome)
    setIndicacaoMTC(Model.Indicmtc)
    setIndicacaoClinica(Model.Indicclinica)

  };

  function Limpar() {
    setNovo(false)
    setId('')
    setNome('')
    setIndicacaoMTC('')
    setIndicacaoClinica('')

  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarComFiltro',
      data: { Buscar: `%${Buscar}%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setFitoterapia(e.result);
        } else {
          setLoadingPage(false);
          setFitoterapia([]);
        }
      })
  };


  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'NovoFitoterapia',
      data: {
        Nome: Nome,
        Indicmtc:IndicacaoMTC,
        Indicclinica:IndicacaoClinica
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistros()
          Limpar()
          message.success('Fitoterapia criado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao criar fitoterapia')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarFitoterapia',
      data: {
        Id: Id,
        Nome: Nome,
        Indicmtc:IndicacaoMTC,
        Indicclinica:IndicacaoClinica
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Fitoterapia editado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao editar fitoterapia')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarFitoterapia',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Fitoterapia excluído com sucesso')
        } else {
          setLoadingPage(false);
          BuscarRegistros()
          message.success('Falha ao excluir fitoterapia')
        }
      })
  };


  useEffect(() => {
    BuscarRegistros()
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }


  const columns = [
    {
      title: 'NOME',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'INDICAÇÃO MTC',
      dataIndex: 'Indicmtc',
      key: 'Indicmtc',
    },
    {
      title: 'INDICAÇÃO CLÍNICA',
      dataIndex: 'Indicclinica',
      key: 'Indicclinica',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          <Search
            style={{ marginLeft: 10, width: 400 }}
            placeholder="Buscar"
            enterButton="Buscar"
            size="large"
            onSearch={onSearch}
          />
        </div>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Fitoterapia} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar Fitoterapia" : "Novo Fitoterapia"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>


        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Nome</label>
            <Input value={Nome} onChange={(e) => setNome(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Nome" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Indicação MTC</label>
            <Input.TextArea value={IndicacaoMTC} onChange={(e) => setIndicacaoMTC(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Indicação MTC" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Indicação Clínica</label>
            <Input.TextArea value={IndicacaoClinica} onChange={(e) => setIndicacaoClinica(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Indicação Clínica" />
          </div>
        </div>



      </Drawer>

    </div>
  );
};



export default Fitoterapia;
