export const DBConfig = {
    name: 'Banco4',
    version: 1,
    objectStoresMeta: [

      {
        store: 'categorias',
        storeConfig: { keyPath: 'Id', autoIncrement: true },
        storeSchema: [
          { name: 'Categoria', keypath: 'Categoria', options: { unique: true } }
        ]
      },
      {
        store: 'produtos',
        storeConfig: { keyPath: 'Id', autoIncrement: true },
        storeSchema: [
          { name: 'IdCategoria', keypath: 'Categoria', options: { unique: false } },
          { name: 'Produto', keypath: 'Produto', options: { unique: false } },
          { name: 'Descricao', keypath: 'Descricao', options: { unique: false } },
          { name: 'Imagem', keypath: 'Imagem', options: { unique: false } },
          { name: 'Preco', keypath: 'Preco', options: { unique: false } }
        ]
      }

    ]
  };