import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { MdPersonPin } from 'react-icons/md';
import { Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import './header.css';

function Header({ Open, setOpen }) {
    const navigate = useNavigate();

    function Sair() {
        localStorage.clear();
        navigate('/');
    }

    return (
        <div className='Header'>

            <div className='Header_1'>
                <Space>

                    <Button onClick={() => setOpen(!Open)} type="primary" size="large" style={{ width: 50, height: 50, borderRadius: 5, backgroundColor: '#1A2B52', color: '#FFFFFF', fontSize: 23 }} icon={<MenuOutlined />} />

                    <h1 style={{ color: '#FFFFFF' }}>Acupuntura Pro</h1>

                </Space>
            </div>

            <div className='Header_2'>

            </div>

            <div className='Header_3'>

            </div>

            <div className='Header_4'>
                <Space>


                    <Button onClick={Sair} type="primary" size="large" style={{ width: 120, height: 50, borderRadius: 5, backgroundColor: '#1A2B52', fontSize: 18 }} icon={<LogoutOutlined />}  >
                        Sair
                    </Button>

                    {/* <Button type="primary" size="large" style={{ width: 50, height: 50, borderRadius: 5, backgroundColor: '#445375', fontSize: 23 }} icon={<MdPersonPin style={{ fontSize: 32 }} />} /> */}

                </Space>
            </div>

        </div>
    );
}

export default Header;
