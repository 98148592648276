import './home.css';

function Home() {
  return (
    <div className='ContainerHome'>
    
    </div>
  );
};

export default Home;
