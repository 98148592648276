import { useState, useEffect } from 'react'
import Config from './../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress } from 'antd';
import { LockOutlined, EyeOutlined, StopOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import axios from 'axios';
import { cpf as CPFValid } from 'cpf-cnpj-validator';
import './style.css';
const { Search } = Input;
const moment = require('moment-timezone');

function Clientes() {
  const [api, contextHolder] = notification.useNotification();
  const [Novo, setNovo] = useState(false);
  const [Assinatura, setAssinatura] = useState(false);
  const [Assinaturas, setAssinaturas] = useState([]);
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Clientes, setClientes] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Nome, setNome] = useState('');
  const [Nascimento, setNascimento] = useState('');
  const [Cpf, setCpf] = useState('');
  const [Sexo, setSexo] = useState('');
  const [Telefone, setTelefone] = useState('');
  const [Whatsapp, setWhatsapp] = useState('');
  const [Email, setEmail] = useState('');
  const [Nome_empresa, setNome_empresa] = useState('');
  const [Pais, setPais] = useState('');
  const [CodigoTell, setCodigoTell] = useState('');
  const [Estado, setEstado] = useState('');
  const [Cidade, setCidade] = useState('');
  const [Usuario, setUsuario] = useState('');
  const [Stripe, setStripe] = useState({});

  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setNome(Model.Nome)
    setNascimento(Model.Nascimento)
    setCpf(Model.Cpf)
    setSexo(Model.Sexo)
    setTelefone(Model.Telefone)
    setWhatsapp(Model.Whatsapp)
    setEmail(Model.Email)
    setNome_empresa(Model.Nome_empresa)
    setPais(Model.Pais)
    setCodigoTell(Model.CodigoTell)
    setEstado(Model.Estado)
    setCidade(Model.Cidade)
    setUsuario(Model.Usuario)
    setStripe(Model.stripe ? Model.stripe : {})

  };


  async function ListarAssinaturas(Model) {
    setNome(Model.Nome);
    setId(Model.Id);
    axios({
      method: 'get',
      url: `${Config.Url}ListarAssinaturaByUsuario/${Model.Usuario}`
    }).then(res => {
      if (res.data.sucesso === true) {
        setAssinatura(true);
        setAssinaturas(res.data.result);
      } else {
        setAssinatura(Assinatura);
        setAssinaturas([]);
      };
    });
  };

  async function BuscarClientes() {
    setLoadingPage(true)
    axios({
      method: 'post',
      url: Config.Url + 'ListarUsuarioComFiltro',
      data: { Buscar: `%${Buscar}%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false)
          setClientes(e.result);
        } else {
          setLoadingPage(false)
          setClientes([]);
        }
      })
  };

  async function SendEmail(SenhaNova, IdCliente) {
    axios({
      method: 'post',
      url: Config.Url + 'RecuperarSenha',
      data: { SenhaNova, IdCliente }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          message.success('Senha provisoria enviado para email do cliente com sucesso')
        } else {
          message.success('Falha ao pedir uma senha provisoria.')
        }
      })
  };

  async function PedirSenha(Model) {
    setLoadingPage(true)
    api.destroy(Model.Id)

    const senhaNova = '@SenhaPro' + generateRandomNumber();

    axios({
      method: 'put',
      url: Config.Url + 'EditarUsuarioSenha',
      data: { Id: Model.Id, Senha: senhaNova }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false)
          SendEmail(senhaNova, Model.Id)
        } else {
          setLoadingPage(false)
          message.success('Falha ao pedir uma senha provisoria.')
        }
      })
  }
  ;
  function generateRandomNumber() {
    // Gerar um número aleatório entre 1000 e 9999
    const randomNumber = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
    return randomNumber;
  }

  async function StopCobranca(Model) {
    setLoadingPage(true)
    api.destroy(Model.Id)

    // pmac = parar a cobranca
    message.info('Parando cobranca para ' + Model.Id )

    axios({
      method: 'get',
      url: Config.UrlApiCobranca + 'Assinaturas/' + Model.Id  + '/stopWithClientId',
      data
        : { Id: Model.Id}
    }).then(e => e.data)
      .then(e => {
        if (e.success) {
          setLoadingPage(false)
          Model.ativo = false
          message.success('Cobrança parada com sucesso')
        } else {
          setLoadingPage(false)
          message.success('Falha ao parar cobrança.')
        }
      })

    
  };
  async function StopCobrancaWithId(Model) {
    setLoadingPage(true)
    api.destroy(Model.Id)

    // pmac = parar a cobranca
    message.info('Parando cobranca para ' + Model.paguefacilId )

    axios({
      method: 'get',
      url: Config.UrlApiCobranca + 'Assinaturas/' + Model.paguefacilId  + '/stop',
      data
        : { Id: Model.Id}
    }).then(e => e.data)
      .then(e => {
        if (e.success) {
          setLoadingPage(false)
          Model.ativo = false
          message.success('Cobrança parada com sucesso')
        } else {
          setLoadingPage(false)
          message.success('Falha ao parar cobrança.')
        }
      })

    
  };

  useEffect(() => {
    BuscarClientes();
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => PedirSenha(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja pedir uma senha provisoria para este cliente ?',
      btn,
      key: Model.Id
    });
  }

  function MensagemBloquearAssinatura(Model) {    
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => StopCobranca(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja parar a cobrança dessa assinatura?',
      btn,
      key: Model.Id
    });
  }

  function MensagemBloquearAssinaturaWithId(Model) {    
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => StopCobrancaWithId(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja parar a cobrança dessa assinatura??',
      btn,
      key: Model.Id
    });
  }

  async function EditarCadastro() {
    api.destroy('1');
    axios({
      method: 'put',
      url: Config.Url + 'EditarPainelUsuario',
      data: { Id, Nome, Telefone, Whatsapp, Email }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          message.success('Sucesso')
          setNovo(false)
          BuscarClientes();

        } else {
          setLoadingPage(false)
          message.error(e.men)
        }
      })
  };

  function MensagemEdicao() {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy('1')}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => EditarCadastro()} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja salvar a alteração feita ?',
      btn,
      key: '1'
    });
  }

  async function AbrirWhatsapp(Tel, Pais) {
    let Telefone = Tel;
    Telefone = Telefone.replace(/[ÀÁÂÃÄÅ]/g, '');
    Telefone = Telefone.replace(/[àáâãäå]/g, '');
    Telefone = Telefone.replace(/[ÈÉÊË]/g, '');
    Telefone = Telefone.replace(/[^a-z0-9]/gi, '');

    if (Tel) {
      window.open('https://wa.me/' + Pais + Telefone, "_blank");
    }

  };

  const columns = [
    {
      title: 'EMPRESA',
      dataIndex: 'Nome_empresa',
      key: 'Nome_empresa',
    },
    {
      title: 'NOME',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'NASCIMENTO',
      dataIndex: 'Nascimento',
      key: 'Nascimento',
    },
    {
      title: 'CPF',
      dataIndex: 'Cpf',
      key: 'Cpf',
    },
    {
      title: 'EMAIL',
      dataIndex: 'Email',
      key: 'Email'
    },
    {
      title: 'TELEFONE',
      dataIndex: 'Telefone',
      key: 'Telefone'
    },
    {
      title: 'WHATSAPP',
      dataIndex: 'Whatsapp',
      key: 'Whatsapp',
      render: (dataIndex, key) =>
        <a onClick={() => AbrirWhatsapp(dataIndex, key['CodigoTell'])}><text>{dataIndex}</text></a>

    },
    {
      title: 'USUARIO',
      dataIndex: 'Usuario',
      key: 'Usuario',
    },
    {
      title: 'STATUS',
      dataIndex: 'ativo',
      key: 'ativo',
      render: (dataIndex, key) =>
        <div> {RetornaStatus(key)}</div>
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Ver' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} title='Pedir nova senha' type="primary" style={{ backgroundColor: 'green' }} shape="circle" icon={<LockOutlined />} />
          <Button onClick={() => ListarAssinaturas(key)} title='Assinaturas' type="primary" style={{ backgroundColor: 'green' }} shape="circle" icon={<MoneyCollectOutlined />} />
          {/* <Button onClick={() => MensagemBloquearAssinatura(key)} title='Parar cobrança de assinatura' type="primary" style={{ backgroundColor: 'red' }} shape="circle" icon={<StopOutlined />} /> */}
        </Space>
    },
  ];

  function RetornaStatus(model) {
    if (model.ativo) {
      return 'ATIVA' 
    }else if (model.ativo === false) {
      return 'INATIVA' 
    } else {
      return ''
    }
  };

  function RetornaAcoes(model) {
    if (model.ativo) {
      //return <Button onClick={() => console.log('excluir : ' + model.id + '--' + JSON.stringify(model))} title='Cancelar assinatura' type="primary" danger shape="circle" icon={<StopOutlined />} /> 
      return <Button onClick={() => MensagemBloquearAssinaturaWithId(model)} title='Cancelar assinatura' type="primary" danger shape="circle" icon={<StopOutlined />} /> 
    }else if (model.ativo === false) {
      return '' 
    } else {
      return ''
    }
  };

  const columnsAssinaturas = [
    {
      title: 'Status',
      dataIndex: 'Id',
      key: 'idAssinatura',
      render: (dataIndex, key) =>
        <div> {RetornaStatus(key)}</div>
    },
    {
      title: 'paguefacilId',
      dataIndex: 'paguefacilId',
      key: 'paguefacilId',
    },
    {
      title: 'Forma de Pagamento',
      dataIndex: 'method_pay',
      key: 'method_pay',
    },
    {
      title: 'Primeira Cobrança',
      dataIndex: 'first_due_date',
      key: 'first_due_date',
      render: (dataIndex, key) =>
      <div> {moment(key.first_due_date).format('DD/MM/YYYY')}</div>
    },
    {
      title: 'Data de Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (dataIndex, key) =>
      <div> {moment(key.createdAt).format('DD/MM/YYYY HH:mm:ss')}</div>
    },
     {
       title: 'AÇÕES',
       dataIndex: 'acao',
       key: 'x',
       render: (dataIndex, key) =>
         <Space wrap>
           {RetornaAcoes(key)}
         </Space>
     },
  ];

  return ( 
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          {/* <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button> */}
        </Space>
        <Search
          style={{ marginLeft: 10, width: 400 }}
          placeholder="Buscar por nome ou usuário"
          enterButton="Buscar"
          size="large"
          onSearch={onSearch}
        />
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Clientes} max columns={columns} />
      </div>

      <Drawer
        title="Registro do Cliente"
        closable={false}
        width={650}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => setNovo(false)}>Fechar</Button>
            <Button type="primary" onClick={() => MensagemEdicao()}>Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Nome</label>
            <Input value={Nome} onChange={(e)=>setNome(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Nome" />
          </div>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Nascimento</label>
            <Input value={Nascimento} disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Nascimento" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Cpf</label>
            <Input value={Cpf}disabled size={'large'} style={{ width: '100%', color: CPFValid.isValid(Cpf) ? 'white' : 'black', backgroundColor: CPFValid.isValid(Cpf) ? 'green' : 'white' }} placeholder="Cpf" />
          </div>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Sexo</label>
            <Input value={Sexo}disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Sexo" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Telefone</label>
            <Input value={Telefone}  onChange={(e)=>setTelefone(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Telefone" />
          </div>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Whatsapp</label>
            <Input value={Whatsapp} onChange={(e)=>setWhatsapp(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Whatsapp" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Email</label>
            <Input value={Email} onChange={(e)=>setEmail(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Email" />
          </div>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Empresa</label>
            <Input value={Nome_empresa}disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Nome da empresa" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Pais</label>
            <Input value={Pais}disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Pais" />
          </div>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Codigo de area</label>
            <Input value={CodigoTell}disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Codigo de area" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Estado</label>
            <Input value={Estado}disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Estado" />
          </div>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Cidade</label>
            <Input value={Cidade}disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Cidade" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Usuário</label>
            <Input value={Usuario}disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Usuário" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Assinatura</label>

            <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
                <label>Codigo do Cliente</label>
                <Input value={Stripe.Id_cliente} disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Estado" />
              </div>
              <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
                <label>Codigo da assinatura</label>
                <Input value={Stripe.Codigo_assinatura}disabled size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Cidade" />
              </div>
            </div>

          </div>
        </div>

      </Drawer>

      <Drawer
        title='Assinaturas CobreFacil'
        closable={false}
        width={'50%'}
        placement="right"
        open={Assinatura}
        extra={
          <Space>
            <Button onClick={() => [setAssinatura(!Assinatura)]}>Fechar</Button>
          </Space>
        }>
        <Table dataSource={Assinaturas} max columns={columnsAssinaturas} />
      </Drawer>

    </div>
  );
};



export default Clientes;
