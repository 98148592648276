import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { SendOutlined } from '@ant-design/icons';
import { ReactFlvPlayer } from 'flv-player-react'
import { Input, Button } from 'antd';
import moment from 'moment-timezone';
import axios from 'axios';
import './home.css'
const { TextArea } = Input;

function Live() {

  const [LiveOn, setLiveOn] = useState([]);
  const [Mensagens, setMensagens] = useState([]);
  const [Usuarios, setUsuarios] = useState([]);
  const [Qnt, setQnt] = useState(0);
  const LiveId = LiveOn.map(e => e.Id).toString()

  const [Mensagem, setMensagem] = useState([]);


  function FiltrarNome(params) {
    const res = Usuarios.filter(e => e.Id === params);
    const Nome = res.map(e => e.Nome);
    return Nome
  };

  async function BuscarLive() {
    await axios({
      method: 'post',
      url: Config.Url + 'ListarLiveAtivas'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLiveOn(e.result);
        } else {
          setLiveOn([]);
        };
      });
  };

  async function EnviarMensagem() {
    await axios({
      method: 'post',
      url: Config.Url + 'CriarLiveMensagem',
      data: {
        Mensagem: Mensagem,
        Live: LiveId,
        Usuario: 'cus_MwMAaY81OAO1cJ'
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setMensagem('');
        } else {
          alert('Erro ao enviar mensagem');
        };
      });
  };

  async function BuscarMensagens() {
    await axios({
      method: 'post',
      url: Config.Url + 'ListarLiveAtivasMensagem',
      data: { Live: LiveId }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          if (e.result.length > Qnt) {
            setQnt(e.result.length)
            setTimeout(() => {
              window.location.href = '#fffff'
            }, 1500);
          };
          setMensagens(e.result);
          setUsuarios(e.usuario);
        } else {
          setMensagens([]);
        };
      });
  };

  const [Contador, setContador] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setContador(Contador + 1)
    }, 2000);
    BuscarMensagens()
  }, [Contador])

  useEffect(() => {
    BuscarLive()
  }, [])

  return (
    <div className='ContainerHomelive'>

      <div style={{ width: '70%', height: '100%', backgroundColor: Config.Primary }}>
        {LiveOn.map((e, index) => (
          <ReactFlvPlayer
            key={index}
            url={`https://striming.acupunturapro.com/live/${e.CodigoLive}.flv`}
            heigh="100%"
            width="100%"
            isLive={true}
            hasAudio={true}
            hasVideo={true}
            isMuted={false}
          />
        ))}
      </div>

      <div style={{ width: '30%', height: '100%', backgroundColor: Config.BRANCO, padding: 20 }}>

        <div style={{ width: '100%', height: '90%', overflow: 'auto', backgroundColor: Config.BRANCO, padding: 20 }}>
          {Mensagens.map((e, index) => {

            if (e.Usuario === localStorage.getItem('Id')) {
              return (
                <div class="container darker">
                  <h3 class="right">{FiltrarNome(e.Usuario)}</h3>
                  <p>{e.Mensagem}</p>
                  <span class="time-left">{moment(e.createdAt).tz("America/Sao_Paulo").format('HH:mm')}</span>
                </div>
              )
            } else {
              return (
                <div class="container">
                  <h3 class="right">{FiltrarNome(e.Usuario)}</h3>
                  <p>{e.Mensagem}</p>
                  <span class="time-left">{moment(e.createdAt).tz("America/Sao_Paulo").format('HH:mm')}</span>
                </div>
              )
            }

          })}
          <div id="fffff" />
        </div>

        <div style={{ height: '10%', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <TextArea
            style={{ height: 80, width: '80%' }}
            placeholder="Mensagem..."
            autoSize={{
              minRows: 2,
              maxRows: 6,
            }}
            value={Mensagem}
            onChange={(e) => setMensagem(e.target.value)}
          />

          <Button onClick={EnviarMensagem} type="primary" size="large" style={{ width: '15%', marginLeft: 5, height: 58, borderRadius: 5, backgroundColor: '#1A2B52', color: '#fff', fontSize: 23 }} icon={<SendOutlined />} />

        </div>

      </div>

    </div>
  );
};

export default Live;
