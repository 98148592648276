import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress, Select, Upload } from 'antd';
import { DeleteOutlined, EyeOutlined, LoadingOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function SubCategorias() {
  const [api, contextHolder] = notification.useNotification();
  const [Loading, setLoading] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [NovoModulo, setNovoModulo] = useState(false);
  const [NovoVideo, setNovoVideo] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [Categorias, setCategoria] = useState([]);
  const [Modulos, setModulos] = useState([]);
  const [Videos, setVideos] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const [CategoriaID, setCategoriaID] = useState('');
  const onSearch = (value) => setBuscar(value);

  const RegistrosFilter = CategoriaID === '' ? Registros : Registros.filter(e => e.Categoria_video === CategoriaID)

  const [Id, setId] = useState('');
  const [Nome, setNome] = useState('');
  const [Categoria_video, setCategoria_video] = useState('');

  const [IdModulo, setIdModulo] = useState('');
  const [NomeModulo, setNomeModulo] = useState('');

  const [IdVideo, setIdVideo] = useState('');
  const [Ordem, setOrdem] = useState('');
  const [Titulo, setTitulo] = useState('');
  const [Descricao, setDescricao] = useState('');
  const [Apostila, setApostila] = useState('');
  const [Video, setVideo] = useState('');


  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setNome(Model.Nome)
    setCategoria_video(Model.Categoria_video)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setNome('')
    setCategoria_video('')
  };

  function AbrirRegistroModulo(Model) {
    setNovoModulo(true)
    setIdModulo(Model.Id)
    setNomeModulo(Model.Nome)
  };

  function LimparModulo() {
    setNovoModulo(false)
    setIdModulo('')
    setNomeModulo('')
  };

  function AbrirRegistroVideos(Model) {
    setNovoVideo(true)
    setIdVideo(Model.Id)
    setOrdem(Model.Ordem)
    setTitulo(Model.Titulo)
    setDescricao(Model.Descricao)
    setApostila(Model.Apostila)
    setVideo(Model.Video)
  };

  function LimparVideos() {
    setNovoVideo(false)
    setIdVideo('')
    setOrdem('')
    setTitulo('')
    setDescricao('')
    setApostila('')
    setVideo('')
  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarSub_videosFiltro',
      data: { Buscar: `%${Buscar}%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setRegistros(e.result);
        } else {
          setLoadingPage(false);
          setRegistros([]);
        }
      })
  };

  async function BuscarRegistrosCategorias() {
    axios({
      method: 'post',
      url: Config.Url + 'ListarCategoria_videos'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setCategoria(e.result);
        } else {
          setCategoria([]);
        }
      })
  };

  async function BuscarRegistrosModulos() {
    axios({
      method: 'post',
      url: Config.Url + 'ListarModulos_videosAll'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setModulos(e.result);
        } else {
          setModulos([]);
        }
      })
  };

  async function BuscarRegistrosVideos() {
    axios({
      method: 'post',
      url: Config.Url + 'ListarvideosAll'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setVideos(e.result);
        } else {
          setVideos([]);
        }
      })
  };

  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'NovoSub_videos',
      data: {
        Nome,
        Categoria_video
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistros()
          Limpar()
          message.success('Video criada com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao criar video')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarSub_videos',
      data: {
        Id: Id,
        Nome,
        Categoria_video
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Video editada com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao editar video')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarSub_videos',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Video excluído com sucesso')
        } else {
          setLoadingPage(false);
          BuscarRegistros()
          message.success('Falha ao excluir video')
        }
      })
  };

  async function SalvarModulos() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'NovoModulos_videos',
      data: {
        Nome: NomeModulo,
        Sub_Videos: Id
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistrosModulos()
          LimparModulo()
          message.success('Video criada com sucesso')
        } else {
          BuscarRegistrosModulos()
          setLoadingPage(false);
          message.success('Falha ao criar video')
        }
      });
  };

  async function EditarModulos() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarModulos_videos',
      data: {
        Id: IdModulo,
        Nome: NomeModulo,
        Sub_Videos: Id
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          LimparModulo()
          BuscarRegistrosModulos()
          message.success('Video editada com sucesso')
        } else {
          BuscarRegistrosModulos()
          setLoadingPage(false);
          message.success('Falha ao editar sub video')
        }
      })
  };

  async function DeletarModulo(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarModulos_videos',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          LimparModulo()
          BuscarRegistrosModulos()
          message.success('Video excluído com sucesso')
        } else {
          setLoadingPage(false);
          BuscarRegistrosModulos()
          message.success('Falha ao excluir video')
        }
      })
  };

  async function SalvarVideos() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'Novovideos',
      data: {
        Ordem: Ordem,
        Titulo: Titulo,
        Descricao: Descricao,
        Apostila: Apostila,
        Video: Video,
        Modulos_Videos: IdModulo
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistrosVideos()
          LimparVideos()
          message.success('Video criada com sucesso')
        } else {
          BuscarRegistrosVideos()
          setLoadingPage(false);
          message.success('Falha ao criar sub video')
        }
      });
  };


  console.log({
    Id: IdVideo,
    Ordem: Ordem,
    Titulo: Titulo,
    Descricao: Descricao,
    Apostila: Apostila,
    Video: Video,
    Modulos_Videos: IdModulo
  })

  async function EditarVideos() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'Editarvideos',
      data: {
        Id: IdVideo,
        Ordem: Ordem,
        Titulo: Titulo,
        Descricao: Descricao,
        Apostila: Apostila,
        Video: Video,
        Modulos_Videos: IdModulo
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          LimparVideos()
          BuscarRegistrosVideos()
          message.success('Video editada com sucesso')
        } else {
          BuscarRegistrosVideos()
          setLoadingPage(false);
          message.success('Falha ao editar sub video')
        }
      })
  };

  async function DeletarVideos(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'Deletarvideos',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          LimparVideos()
          BuscarRegistrosVideos()
          message.success('Video excluído com sucesso')
        } else {
          setLoadingPage(false);
          BuscarRegistrosVideos()
          message.success('Falha ao excluir sub video')
        }
      })
  };

  useEffect(() => {
    BuscarRegistrosCategorias()
    BuscarRegistrosModulos()
    BuscarRegistrosVideos()
  }, [])

  useEffect(() => {
    BuscarRegistros()
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }

  function MensagemPedirSenhaModulo(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => DeletarModulo(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }

  function MensagemPedirSenhaVideo(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => DeletarVideos(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }

  const columns = [
    {
      title: 'Sub Categoria',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  const columnsModulos = [
    {
      title: 'MODULO',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistroModulo(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenhaModulo(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  const columnsVideos = [
    {
      title: 'ORDEM',
      dataIndex: 'Ordem',
      key: 'Ordem',
    },
    {
      title: 'TÍTULO',
      dataIndex: 'Titulo',
      key: 'Titulo',
    },
    {
      title: 'DESCRIÇÃO',
      dataIndex: 'Descricao',
      key: 'Descricao',
    },
    {
      title: 'APOSTILA',
      dataIndex: 'Apostila',
      key: 'Apostila',
    },
    {
      title: 'VIDEO',
      dataIndex: 'Video',
      key: 'Video',
      render: (dataIndex, key) =>
        <video width="250" height="180" style={{borderRadius:10}} controls>
          <source    src={Config.Url + 'Videos/' + dataIndex} type="video/mp4" />
        </video>
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistroVideos(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenhaVideo(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  const props = {
    name: 'file',
    multiple: false,
    action: Config.Url + 'UploadsVideos',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setVideo(info.file.name)
        message.success(`${info.file.name} teve sucesso ao subir para o servidor.`);
      } else if (status === 'error') {
        setVideo('')
        message.error(`${info.file.name} falhou ao subir para o servidor.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          <Select onSelect={(e) => setCategoriaID(e)} size='large' style={{ width: 200 }} placeholder="Categorias">
            <option value="" >Todos</option>
            {Categorias.map((e, index) => (
              <option key={index} value={e.Id} >{e.Nome}</option>
            ))}
          </Select>
          <Search
            style={{ marginLeft: 10, width: 400 }}
            placeholder="Buscar por nome"
            enterButton="Buscar"
            size="large"
            onSearch={onSearch}
          />
        </div>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={RegistrosFilter} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar Sub Categoria" : "Nova Sub Categoria"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Categoria</label>
            <Select value={Categoria_video} onSelect={(e) => setCategoria_video(e)} size='large' style={{ width: '100%' }} placeholder="Categorias">
              <option value="" >Categoria</option>
              {Categorias.map((e, index) => (
                <option key={index} value={e.Id} >{e.Nome}</option>
              ))}
            </Select>
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Sub Categoria</label>
            <Input value={Nome} onChange={(e) => setNome(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Categoria" />
          </div>
        </div>

        {Id && <div className='ContainerTableNav'>
          <text>Modulo</text>
          <Space>
            <Button style={{ height: 30 }} type="primary" onClick={() => setNovoModulo(true)}>ADICIONAR</Button>
          </Space>
        </div>}

        {Id &&
          <div className='ContainerTable'>
            <Table dataSource={Modulos.filter(e => e.Sub_Videos === Id)} max columns={columnsModulos} />
          </div>}

      </Drawer>


      <Drawer
        title={IdModulo !== '' ? "Editar Modulo" : "Novo Modulo"}
        closable={false}
        width={IdModulo ? '80%' : 480}
        placement="left"
        open={NovoModulo}
        extra={
          <Space>
            <Button onClick={() => [setNovoModulo(false), LimparModulo()]}>Fechar</Button>
            <Button type="primary" onClick={() => IdModulo !== '' ? EditarModulos() : SalvarModulos()} >Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Modulo</label>
            <Input value={NomeModulo} onChange={(e) => setNomeModulo(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Modulo" />
          </div>
        </div>

        {IdModulo && <div className='ContainerTableNav'>
          <text>Modulo</text>
          <Space>
            <Button style={{ height: 30 }} type="primary" onClick={() => setNovoVideo(true)}>ADICIONAR</Button>
          </Space>
        </div>}

        {IdModulo &&
          <div className='ContainerTable'>
            <Table dataSource={Videos.filter(e => e.Modulos_Videos === IdModulo)} max columns={columnsVideos} />
          </div>}

      </Drawer>


      <Drawer
        title={IdVideo !== '' ? "Editar Video" : "Novo Video"}
        closable={false}
        width={580}
        placement="left"
        open={NovoVideo}
        extra={
          <Space>
            <Button onClick={() => [setNovoVideo(false), LimparVideos()]}>Fechar</Button>
            <Button type="primary" onClick={() => IdVideo !== '' ? EditarVideos() : SalvarVideos()} >Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Título</label>
            <Input value={Titulo} onChange={(e) => setTitulo(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Título" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Descrição</label>
            <Input.TextArea value={Descricao} onChange={(e) => setDescricao(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Descrição" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Ordem</label>
            <Input value={Ordem} onChange={(e) => setOrdem(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Ordem" />
          </div>

          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Apostila</label>
            <Input value={Apostila} onChange={(e) => setApostila(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Apostila" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Upload.Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click para {IdVideo ? "editar":"adicionar"} um video</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from uploading company data or other
              banned files.
            </p>
          </Upload.Dragger>
        </div>

      </Drawer>

    </div>
  );
};



export default SubCategorias;
