import { useState } from 'react';
import {  Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '../Components/Header';
import Menu from '../Components/Menu';

import Home from './../Pages/Home';
import Clientes from '../Pages/Clientes';
import Meridianos from '../Pages/Pontos/meridianos';
import Pontos from '../Pages/Pontos/pontos';
import Auxiliar from '../Pages/Diagnostico/auxiliar';
import Sintomas from '../Pages/Diagnostico/sintomas';
import Mapas from '../Pages/Mapas/mapas';
import Imagens from '../Pages/Mapas/imagens';
import Fitoterapia from '../Pages/Fitoterapia';
import AromaTerapia from '../Pages/Extras/Aromaterapia';
import Ditoterapia from '../Pages/Extras/Dietoterapia';
import CromoTerapia from '../Pages/Extras/Comoterapia';
import Floraisdebach from '../Pages/Extras/FloraisdeBach';
import Plantasteraputicas from '../Pages/Extras/PlantasTerapeuticas';
import Quiz from '../Pages/Quiz/quiz';
import Glossario from '../Pages/Glossario';
import Academia from '../Pages/Academia';
import SubCategorias from '../Pages/Academia/sub';
import Biblioteca from '../Pages/Biblioteca';
import Comunidade from '../Pages/Comunidade';
import ComunidadeRespsotas from '../Pages/Comunidade/respostas';
import RefBibliografica from '../Pages/RefBibliograficas';
import Politica from '../Pages/Politica';
import Termos from '../Pages/Termos';
import Configuracoes from '../Pages/Configuracoes';
import Notificacoes from '../Pages/Notificacoes';
import Lives from '../Pages/Lives';
import Live from '../Pages/Live'

const Paginas = () => {
  return (
    <Routes>
      <Route path='/clientes' element={<Clientes />} />
      <Route path='/meridianos' element={<Meridianos />} />
      <Route path='/pontos' element={<Pontos />} />
      <Route path='/auxiliares' element={<Auxiliar />} />
      <Route path='/sintomas' element={<Sintomas />} />
      <Route path='/tabelasmapas' element={<Mapas />} />
      <Route path='/imagens' element={<Imagens />} />
      <Route path='/fitoterapia' element={<Fitoterapia />} />
      <Route path='/aromaterapia' element={<AromaTerapia />} />
      <Route path='/dietoterapia' element={<Ditoterapia />} />
      <Route path='/cromoterapia' element={<CromoTerapia />} />
      <Route path='/floraisdebach' element={<Floraisdebach />} />
      <Route path='/plantasterapeuticas' element={<Plantasteraputicas />} />
      <Route path='/perguntas' element={<Quiz />} />
      <Route path='/glossario' element={<Glossario />} />
      <Route path='/categoria' element={<Academia />} />
      <Route path='/subcategoria' element={<SubCategorias />} />
      <Route path='/biblioteca' element={<Biblioteca />} />
      <Route path='/comunidade' element={<Comunidade />} />
      <Route path='/solicitacoes' element={<ComunidadeRespsotas />} />
      <Route path='/refbibliograficas' element={<RefBibliografica />} />
      <Route path='/politicaeprivacidade' element={<Politica />} />
      <Route path='/termosdeuso' element={<Termos />} />
      <Route path='/configuracoes' element={<Configuracoes />} />
      <Route path='/notificacao' element={<Notificacoes />} />
      <Route path='/lives' element={<Lives />} />
      <Route path='/live/:LiveId' element={<Live />} />
      <Route path='/*' element={<Home />} />
    </Routes>
  );
};

function Construtor() {

  const [Open, setOpen] = useState(false);

  return (
    <Layout style={{ overflow: 'hidden', overflowX: 'hidden', overflowY: 'hidden', backgroundColor: '#F0F0F0' }}>
      <Header Open={Open} setOpen={setOpen} />
      <Menu Open={Open} setOpen={setOpen} />
      <Paginas />
    </Layout>
  );
};

export default Construtor;
