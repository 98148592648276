import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from "../Pages/Login/indes";
import Lottie from 'react-lottie';
import Block from './../Images/block.json';
import Construtor from './Construtor';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';


function ContrutorLogin() {
    const [Autorizado, setAutorizado] = useState(null);
    const navigate = useNavigate();


    async function Vereficaautenticidade() {
        if (localStorage.getItem('$f5e1fe1fef5e1f6e51fe') === 'true') {
            navigate('dashboard/clientes');
            message.success('Bem vindo Wander.');
            setAutorizado(true)
        } else {
            localStorage.clear()
            setAutorizado(true)
            message.error('Insira seu usuário administrativo para prosseguir.')
        }
    };
   
    useEffect(() => {
        Vereficaautenticidade();
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Block,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    if (Autorizado) {
        return (
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/index.html' element={<Login />} />
                {localStorage.getItem('$f5e1fe1fef5e1f6e51fe') === 'true' && <Route path='/dashboard/*' element={<Construtor />} />}
                {localStorage.getItem('$f5e1fe1fef5e1f6e51fe') === 'true' && <Route path='/index.html/dashboard/*' element={<Construtor />} />}
            </Routes>
        );
    } else {
        return (
            <div style={{ position: 'absolute', backgroundColor: "#1A2B52", width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                <div style={{ backgroundColor: '#fff', borderRadius: 10, flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie
                        options={defaultOptions}
                        height={400}
                        width={400}
                    />
                    <h1 style={{ position: 'absolute', marginTop: 300, color: "#1A2B52" }}>{Autorizado === null ? "Verificando" : Autorizado === null ? "Autorizado" : "Sem Autorização"}</h1>
                </div>
            </div>
        );
    };

};

export default ContrutorLogin;
