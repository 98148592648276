import { useState, useEffect } from 'react'
import Config from '../../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function Floraisdebach() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Nome, setNome] = useState('');
  const [Indic, setIndic] = useState('');

  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setNome(Model.Nome)
    setIndic(Model.Indic)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setNome('')
    setIndic('')
  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarFloraisComFiltro',
      data: { Buscar: `%${Buscar}%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setRegistros(e.result);
        } else {
          setLoadingPage(false);
          setRegistros([]);
        }
      })
  };


  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'NovoFlorais',
      data: {
        Nome,
         Indic
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistros()
          Limpar()
          message.success('Floral criada com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao criar floral')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarFlorais',
      data: {
        Id: Id,
        Nome,
         Indic
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Floral editada com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao editar floral')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarFlorais',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Floral excluída com sucesso')
        } else {
          setLoadingPage(false);
          BuscarRegistros()
          message.success('Falha ao excluir floral')
        }
      })
  };


  useEffect(() => {
    BuscarRegistros()
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }


  const columns = [
    {
      title: 'NOME',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'INDICAÇÕES',
      dataIndex: 'Indic',
      key: 'Indic',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          <Search
            style={{ marginLeft: 10, width: 400 }}
            placeholder="Buscar"
            enterButton="Buscar"
            size="large"
            onSearch={onSearch}
          />
        </div>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Registros} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar Floral" : "Novo Floral"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>


        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Nome</label>
            <Input value={Nome} onChange={(e) => setNome(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Nome" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Indicações</label>
            <Input.TextArea value={Indic} onChange={(e) => setIndic(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Indicações" />
          </div>
        </div>

      </Drawer>

    </div>
  );
};



export default Floraisdebach;


