import Lottie from 'react-lottie';
import Loading from './../../Images/loading.json';

function LoadingPage() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Loading,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ position: 'absolute', backgroundColor: "#000", width: '100%', height: '100%', opacity: 0.5, }} />
            <Lottie
                options={defaultOptions}
                height={400}
                width={400}
            />
        </div>
    );
}

export default LoadingPage;
